const Checkbox = ({id, value, setData, error}: {id: string, value: boolean, setData: Function, error: string}) => {
    return (
        <div className='flex flex-row gap-1'>
            <label className={'text-sm font-bold ' + (error.length > 0 ? 'text-red-600' : '')} htmlFor={id}>{id}</label>
            <input checked={value} type='checkbox' id={id} className={'px-1 ' + (error.length > 0 ? 'border-red-600':  'border-black') + ' border-2 rounded'} onChange={e => {
                setData(!value);
            }}/>
        </div>
    );
}

export default Checkbox;